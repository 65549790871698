<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      {{ headerTitle }}
     <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <!-- 인수인계 -->
      <template>
        <div class="d-flex">
          <div>
            <div class="form-inp sm">
              <v-text-field
                  class="form-inp ml-2"
                  label="사용자ID"
                  outlined
                  hide-details
                  v-model="agentId"
                  @keyup.enter="getList"
              >
              </v-text-field>
            </div>
            <div class="form-inp sm">
              <v-text-field
                  class="form-inp ml-2"
                  label="사용자명"
                  outlined
                  hide-details
                  v-model="agentNm"
                  @keyup.enter="getList"
              >
              </v-text-field>
            </div>
          </div>
          <div class="ml-auto align-self-center mr-0">
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined small class="btn-etc2" id="btnSearch" @click="getList">조회</v-btn>
          </div>
        </div>
        <h2 class="tit-h2 d-flex mt-3">사용자목록</h2>
        <div class="text-right">
          <template>
            <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnRegi')" outlined small class="btn-default ml-2" id="btnSave" @click="processRtn">등록</v-btn>
          </template>
        </div>
        <div class="mt-2">
          <v-data-table
            :headers="gridDataHeaders"
            :items="gridDataText"
            :item-class="isActiveRow"
            item-key="ROW_NUMBER"
            hide-default-footer
            dense
            height="440px"
            fixed-header
            class="grid-default"
            :page.sync="page"
            @page-count="pageCount = $event"
            :items-per-page="30"
            @click:row="selectUser"
            @dblclick:row="processRtn"
          >
          </v-data-table>
        </div>
      </template>
      <div class="grid-paging text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pageCount"
          :total-visible="totalVisible"
          next-icon="svg-paging-next"
          prev-icon="svg-paging-prev"
        ></v-pagination>
      </div>
      <p class="caption text-left">
        총 <strong class="clr-error">{{ gridDataText.length | inputNumberFormat }}</strong>건
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { mixin } from "@/mixin/mixin.js"

export default {
  name: "MENU_M110402P04", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  props: {
    param:{
      type: Object,
      default: () => ({})
    },
    headerTitle: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      gridDataHeaders: [
        { text: "No", value: "ROW_NUMBER", align: "center", sortable: true },
        { text: "상담원사번", value: "AGENT_ID", align: "left", sortable: true },
        { text: "상담원명", value: "AGENT_NM", align: "left", sortable: true },
        { text: "업무그룹명", value: "TM_KIND_NM", align: "center", sortable: true },
        { text: "직책명", value: "AGENT_JIKCHK_NM", align: "center", sortable: true },
        { text: "직급명", value: "AGENT_JIKGUP_NM", align: "center", sortable: true },
        //{ text: "주/야구분", value: "AGENT_DAYNIGHT_GUBUN", align: "center", sortable: false },
      ],
      gridDataText: [],
      gridSelected:{},
      page: 1,
      pageCount: 0,
      itemsPerPage: 100,
      totalVisible: 10,
      agentId: '',
      agentNm: '',
    }
  },
  created() {
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList(){
      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE']= 'phone.outbound.manage.agent-change-inqire-popup';
      reqData.headers['SQL_NM']= 'inqire';
      reqData.headers['GRID_ID']= 'divGridCsltList';
      reqData.headers['MENU_ID']= '';
      reqData.headers['ASYNC']= 'false';
      reqData.headers['TYPE']= 'XML_SERVICE';
      reqData.headers['URL']= '/api/phone/outbound/manage/agent-change-inqire-popup/inqire';
      reqData.headers['PAGES_CNT']= 1;
      reqData.headers['ROW_CNT']= 1000;

      reqData.sendData['AGENT_ID']= this.agentId;
      reqData.sendData['AGENT_NM']= this.agentNm;
      reqData.sendData['ASP_NEWCUST_KEY']= this.aspNewcustKey;

      const res = await this.common_postCall(reqData);
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        let dataList = res.DATA;
        this.gridDataText = dataList;
      }
    },
    selectUser(item){
      this.gridSelected = item;
    },
    processRtn(event, item){

      // 선택한 상담원
      let selRowDataObj = this.gridSelected;
      if(selRowDataObj == null){this.common_alert("상담원을(를) 선택해주세요.", "error");return;}

      //상담원 배정 변경
      let num = 0; // 상담건수
      for(let i=0; i < this.objParentJson.length; i++) {
        num += Number( this.objParentJson[i].DIV_EDIT_COL );
      }
      let msg = selRowDataObj.AGENT_NM + " 상담원으로 처리중 아웃바운드 " + num + "건을 이관합니다.";
      this.common_confirm(  msg, this.processRtnCallYes, null, null, null );
    },
    async processRtnCallYes(){
      let data = "";
      let selRowDataObj = this.gridSelected;

      for(let i=0; i < this.objParentJson.length; i++) {
        let num = Number( this.objParentJson[i].DIV_EDIT_COL );
        if(num > 0) {
          // 이전상담원 : 상담원 : 건수
          data += this.objParentJson[i].CSLT_ID + ":" + selRowDataObj.AGENT_ID + ":" + num + "/";
        }
      }

      //서버에 전송할 파라메터객체 생성
      let reqData = {
        headers: {},
        sendData: {}
      };
      reqData.headers['SERVICE']= 'phone.outbound.manage.agent-change-inqire-popup'; //호출 CLASS 서비스ID 정의
      reqData.headers['METHOD']= 'modify'; //호출할 함수 정의
      reqData.headers['ASYNC']= 'false';
      reqData.headers['TYPE']= 'BIZ_SERVICE';
      reqData.headers['URL']= '/api/phone/outbound/manage/agent-change-inqire-popup/modify';


      // 파라미터 세팅
      //objJsonParams.setDataObject(G_DATA, $.getFormData("frmDtlInfo",false));
      reqData.sendData['DATA_FLAG']= "U";						// 등록 및 수정할 사용자정보 파라메터 세팅													//센터구분
      reqData.sendData['DSTR_INFO']= data.substring(0, data.length -1);
      reqData.sendData['CAM_ID_ARR']= this.getCamIds;	 // CAM_ID 배열										//세션정보 처리자 ID 세팅:변경자
      reqData.sendData['CHNG_MAN']= this.geUserRole.userId;											//세션정보 처리자 ID 세팅:변경자
      reqData.sendData['REG_MAN']= this.geUserRole.userId;											//세션정보 처리자 ID 세팅:변경자

      //비즈니스 서비스 호출하여 결과값을 받는다.
      const res = await this.common_postCall(reqData);
      if(!this.mixin_isEmpty(res) && !res.HEADER.ERROR_FLAG) {
        this.common_alert("정상적으로 수정되었습니다.");
        await this.$emit('hideAndGetList');
      }
    },
    isActiveRow(item){
      return this.gridSelected.AGENT_ID === item.AGENT_ID ? 'active' : '';
    },
  },
  filters: {
    inputNumberFormat(v){
      return String(v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  },
  computed: {
    ...mapGetters({
      geUserRole: 'userStore/GE_USER_ROLE',
      geUserCompany: 'userStore/GE_USER_COMPANY'
    }),
    aspNewcustKey() {
      return this.param.ASP_NEWCUST_KEY;
    },
    objParentJson() {
      return this.param.objParentJson;
    },
    getCamIds(){
      return this.param.CAM_ID_ARR;
    }
  },
}
</script>

<style>

</style>
